import React, {
  useState, createRef,
} from 'react';
import {
  Stack, Container, Row, Col, Form, Button,
} from 'react-bootstrap';
import {
  CardComponent, CardNumber, CardExpiry, CardCVV,
} from '@chargebee/chargebee-js-react-wrapper'; // @ts-ignore
import { useForm } from 'react-hook-form';
import DiscountModal from './DiscountModal';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectSignUp,
  setCbToken,
  setCoupon,
  setMarketingTerms,
} from '../features/signup/signupSlice';
import {
  selectDiscount,
  newCampaignDiscount,
  setDiscount,
  discountFromCoupon,
} from '../features/discount/discountSlice';
import OrderSummary, { getDiscountPlanCost } from './OrderSummary';
import {
  Coupon, CouponType,
} from '../features/coupon/couponAPI';
import { postBookingsAsync } from '../features/finale/finaleSlice';
import { selectActiveBookingId } from '../features/bookings/bookingsSlice';
import {
  ArrivyTemplateExtraFields,
} from '../models/arrivy';
import { selectAddress } from '../features/address/addressSlice';

export interface DetailProps {
  onSubmit: any
  previousStep: any
}

interface TermsForm {
  newDevelopment: boolean;
  oneoff: boolean
  monthly: boolean
  terms: boolean
  marketing: boolean
  rapidchecked: boolean
  invoicechecked: boolean
}

const CreditCardInfo = ({
  onSubmit, previousStep,
}: DetailProps) => {
  const cardRef = createRef();
  const dispatch = useAppDispatch();
  const signup = useAppSelector(selectSignUp);
  const booking = useAppSelector(selectActiveBookingId);
  const discount = useAppSelector(selectDiscount);

  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [
    proposedCoupon,
  ] = useState<Coupon>({} as Coupon);

  const [cardErr, setCardErr] = useState('');
  const [cardLoading, setCarLoading] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const [rapidChecked, setRapidChecked] = useState(false);
  const [invoiceChecked, setInvoiceChecked] = useState(false);
  const [chargeChecked, setChargeChecked] = useState(false);
  const [newDevelopmentChargeChecked, setNewDevelopmentChargeChecked] = useState(false);

  const termsForm = useForm<TermsForm>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const addr = useAppSelector(selectAddress);

  const applyDiscounts = (coupon: Coupon) => {
    dispatch(setCoupon(coupon));
    if (coupon.coupon_type === CouponType.ReferralCode || coupon.coupon_type === CouponType.SalesCommission) {
      dispatch(setDiscount(newCampaignDiscount("Referral Code Offer")));
    } else {
      dispatch(setDiscount(discountFromCoupon(coupon)));
    }
  };

  const tokenize = async (termsFormData: any) => {
    setMarketingTerms(termsFormData.marketing);
    setMarketingTerms(termsFormData.terms);
    setChargeChecked(termsFormData.monthly);
    setNewDevelopmentChargeChecked(termsFormData.newDevelopment);
    dispatch(setMarketingTerms({
      marketing: termsFormData.marketing ? 'YES' : 'NO',
      termsandcons: termsFormData.terms ? 'YES' : 'NO',
    }));

    setCarLoading(true);
    // Use card component ref to call tokenize method
    setCardErr('');
    // @ts-ignore
    await cardRef.current.tokenize({}).then((data: any) => {
      dispatch(setCbToken({ cb_token: data.vaultToken }));
      const newSignup = { ...signup, cb_token: data.vaultToken };
      newSignup.arrivyExtraFields = {
        ...newSignup.arrivyExtraFields,
        'I Agree to the Terms and Conditions': {
          type: 'TEXT',
          name: 'I Agree to the Terms and Conditions',
          value: termsFormData.terms ? 'YES' : 'NO',
        } as ArrivyTemplateExtraFields,
        'Marketing Emails': {
          type: 'TEXT',
          name: 'Marketing Emails',
          value: termsFormData.marketing ? 'YES' : 'NO',
        } as ArrivyTemplateExtraFields,
        'Building Code': {
          type: 'TEXT',
          name: 'Building Code',
          value: addr.uniqueCode,
        } as ArrivyTemplateExtraFields,
      };
      dispatch(postBookingsAsync({
        payload: { ...signup.arrivy, title: signup.arrivy.title },
        booking,
        signUp: newSignup,
      }));
      // Send ajax calls
      setCarLoading(false);
      onSubmit();
    }).catch((err: any) => {
      console.log('ERR', err);
      setCardErr('Credit card is invalid, please check details and try again.');
      setCarLoading(false);
    });
  };

  const getPlanCost = () => {
    const planCost = discount?.period !== 0 ? getDiscountPlanCost(Number(signup.plan.cost), discount) : Number(signup.plan.cost);

    return signup.router.description2 === 'per month' ? planCost + Number(signup.router.cost) : planCost;
  };

  return (
    <Container>
      { showDiscountModal && (
      <DiscountModal
        currentDiscount={discount}
        proposedCoupon={proposedCoupon}
        onSubmit={(accepted) => {
          if (accepted) {
            applyDiscounts(proposedCoupon);
          }
          setShowDiscountModal(false);
        }}
      />
      ) }
      <Row className="justify-content-md-center">
        <Col>
          <h1 className="fw-bold text-primary text-center mb-4">
            Secure Payment
          </h1>
        </Col>
      </Row>
      <Row className="position-relative">
        <Col md={8}>
          <CardComponent
            ref={cardRef}
            locale="en"
            className="fieldset field"
            styles={{
              base: {
                fontWeight: '400',
                fontFamily: '"General Sans", "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
                fontSize: '16px',
                color: '#6d6d6d',
                fontSmoothing: 'antialiased',
                ':focus': {
                  color: '#6d6d6d',
                },
                '::placeholder': {
                  color: '#fafafa',
                },
                ':focus::placeholder': {
                  color: '#6d6d6d',
                },
              },
            }}
            placeholder={{
              number: '',
              expiry: 'MM / YY',
              cvv: '',
            }}
            icon
          >
            <Row>
              <div
                style={{ borderTop: '2px solid' }}
                className="
              align-self-end
              py-2
              border-primary"
              >
                <h4>Payment Details</h4>
              </div>
            </Row>
            <Row className="mb-2">
              <Form.Group
                className="col-xs-4 col-md-12"
                as={Col}
                controlId="creditCardNumber"
              >
                <Form.Label>Card Number</Form.Label>
                <CardNumber
                  className="form-control input-with-radius"
                />
                <i className="ex1-bar" />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                className="col-xs-4 col-md-12"
                as={Col}
                controlId="cardHolderName"
              >
                <Form.Label>Cardholders Name</Form.Label>
                <Form.Control
                  className="form-control input-with-radius"
                  defaultValue={`${signup.arrivy.customer_first_name.toUpperCase()} ${signup.arrivy.customer_last_name.toUpperCase()}`}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group
                className="col-xs-2 col-md-6"
                as={Col}
                controlId="creditCardExpiry"
              >
                <Form.Label>Expiry (MM/YY)</Form.Label>
                <CardExpiry
                  className="form-control input-with-radius"
                />
                <i className="ex1-bar" />
              </Form.Group>
              <Form.Group
                className="col-xs-2 col-md-6"
                as={Col}
                controlId="creditCardCVV"
              >
                <Form.Label>CVV</Form.Label>
                <CardCVV
                  className="form-control input-with-radius"
                />
              </Form.Group>
            </Row>

            <Row className="mt-4">
              <h4>Your acceptance</h4>
            </Row>
            <Stack gap={2} direction="vertical" className="mb-3">
              <Form.Check
                type="radio"
                id="monthly"
                checked={chargeChecked}
                onClick={() => setChargeChecked(!chargeChecked)}
                label={(
                  <span>
                    {' I agree to a monthly charge of '}
                    <b>
                      $
                      {getPlanCost()}
                    </b>
                    {' and for this amount to be automatically charged to the payment details provided.'}
                    { discount.terms !== undefined
                      ? ` ${discount.terms}`
                      : null }
                  </span>
)}
                {...termsForm.register('monthly', {
                  required: `Please agree to a monthly charge of $${getPlanCost()}`,
                })}
              />
              { addr.buildingCategory === 'greenfield'
              && (
              <Form.Check
                type="radio"
                id="newDevelopment"
                checked={newDevelopmentChargeChecked}
                onClick={() => setNewDevelopmentChargeChecked(!newDevelopmentChargeChecked)}
                label={(
                  <span>
                    I agree to a one-off New Development Charge of
                    {' '}
                    <strong>$300</strong>
                  </span>
                  )}
                {...termsForm.register('newDevelopment', {
                  required: "Please agree to the New Development Charge of $300.",
                })}
              />
              )}
              <Form.Check
                type="radio"
                id="terms"
                onClick={() => setTermsChecked(!termsChecked)}
                checked={termsChecked}
                label={(
                  <span>
                    {' I confirm that I have read and agree to '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.pineapple.net.au/wp-content/uploads/PN_General-Terms.pdf"
                    >
                      terms and conditions
                    </a>
                    {' of my plan.'}
                  </span>
)}
                {...termsForm.register('terms', {
                  required: 'Please confirm that you have read and agree to the terms and conditions of your plan.',
                })}
              />
              <Form.Check
                type="radio"
                id="rapidchecked"
                checked={rapidChecked}
                onClick={() => setRapidChecked(!rapidChecked)}
                label={(
                  <div style={{ width: '70%' }}>
                    I understand that my new arrangement with Pineapple Net will be on a month to month basis.
                  </div>
              )}
                {...termsForm.register('rapidchecked', {
                  required: `Please confirm that you understand your month to month arrangement with Pineapple Net.`,
                })}
              />
              <Form.Check
                type="radio"
                id="invoicechecked"
                checked={invoiceChecked}
                onClick={() => setInvoiceChecked(!invoiceChecked)}
                label={(
                  <div style={{ width: '70%' }}>
                    I understand that I will receive my 1st invoice in advance
                    from the date my service is connected with Pineapple Net.
                  </div>
              )}
                {...termsForm.register('invoicechecked', {
                  required: `Please confirm that you understand the invoicing terms.`,
                })}
              />
              <Form.Check
                type="radio"
                id="marketing"
                checked={marketingChecked}
                onClick={() => setMarketingChecked(!marketingChecked)}
                label={(
                  <div style={{ width: '70%' }}>
                    {'I would like to receive marketing and promotional communications from Pineapple Net in accordance with the '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.pineapple.net.au/wp-content/uploads/PN_Privacy-Policy_12.23.pdf">
                      Privacy Policy.
                    </a>
                    {' '}
                    Pineapple Net is committed to protecting and respecting your
                    privacy. We will only use your personal information to
                    provide information you have requested, adminster your account,
                    and deliver our products and services to you.
                  </div>
              )}
                {...termsForm.register('marketing', {
                })}
              />
            </Stack>
            <h4 className="text-center text-info mt-3">{cardErr}</h4>
          </CardComponent>
        </Col>
        <Col>
          <OrderSummary />
        </Col>
      </Row>
      <Row><Col className="text-info">{termsForm?.formState?.errors?.newDevelopment ? termsForm.formState.errors.newDevelopment.message : '' }</Col></Row>
      <Row><Col className="text-info">{termsForm?.formState?.errors?.monthly ? termsForm.formState.errors.monthly.message : '' }</Col></Row>
      <Row><Col className="text-info">{termsForm?.formState?.errors?.terms ? termsForm.formState.errors.terms.message : '' }</Col></Row>
      <Row><Col className="text-info">{termsForm?.formState?.errors?.rapidchecked ? termsForm.formState.errors.rapidchecked.message : '' }</Col></Row>
      <Row><Col className="text-info">{termsForm?.formState?.errors?.invoicechecked ? termsForm.formState.errors.invoicechecked.message : '' }</Col></Row>
      <Stack
        gap={2}
        className="justify-content-center my-4"
        direction="horizontal"
      >
        <Button
          id="payment_go_back"
          onClick={previousStep}
          size="lg"
          variant="outline-light"
        >
          <span className="text-body px-2">Go back</span>
        </Button>
        <Button
          id="payment_submit"
          onClick={termsForm.handleSubmit(tokenize)}
          size="lg"
          variant="primary"
          disabled={cardLoading}
        >
          <span className="px-2">{!cardLoading ? 'Submit' : 'Please wait...'}</span>
        </Button>
      </Stack>
    </Container>
  );
};

export default CreditCardInfo;

import React, { useState, createRef } from 'react';
import {
  Stack, Container, Row, Col, Button,
} from 'react-bootstrap';
import ContactInput from './ContactInput';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  setContacts,
} from '../features/signup/signupSlice';
import OrderSummary from './OrderSummary';

export interface DetailProps {
  onSubmit: any
  previousStep: any
  formState: any
  register: any
  getValues: any
  handleSubmit: any
}

const ContactDetails = ({
  onSubmit, previousStep, formState, register, handleSubmit, getValues,
}: DetailProps) => {
  const dispatch = useAppDispatch();

  const next = handleSubmit((values: any) => {
    dispatch(setContacts({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      howDidYouHearAboutUs: values.howDidYouHearAboutUs,
    }));
    onSubmit();
  });

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col>
          <h1 className="fw-bold text-primary text-center mb-4">
            Now let&apos;s get you set up!
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <ContactInput formState={formState} register={register} getValues={getValues} />
        </Col>
        <Col>
          <OrderSummary />
        </Col>
      </Row>
      <Stack
        gap={2}
        className="justify-content-center my-4"
        direction="horizontal"
      >
        <Button
          id="contact_info_go_back"
          onClick={previousStep}
          size="lg"
          variant="outline-light"
        >
          <span className=" text-body px-2">Go back</span>
        </Button>
        <Button
          id="contact_info_continue"
          onClick={next}
          size="lg"
          variant="primary"
        >
          <span className="px-2">Continue</span>
        </Button>
      </Stack>
    </Container>
  );
};

export default ContactDetails;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ordinalSuffixOf } from "../../lib/utils";
import { Coupon, CouponType } from "../coupon/couponAPI";

export const standardCampaignDiscount = {
  period: 0,
  period_unit: "month",
  starting_period: 7,
  dollar_amount: 0,
  percentage: "100",
  in_words: undefined,
  terms:
    "One Month Free credit will be applied to your account on the 7th month after installation.",
} as Discount;

export const newCampaignDiscount = (title: string): Discount => ({
  ...standardCampaignDiscount,
  title,
});

export const discountFromCoupon = (coupon: Coupon): Discount => {
  if (coupon.coupon_type === CouponType.ReferralCode || coupon.coupon_type === CouponType.SalesCommission) {
    return standardCampaignDiscount;
  }

  const specialWords = {
    in_words:
      coupon.coupon_id === "SUMMER20"
        ? "$20 off for 12 months - Southbank Exclusive Summer offer."
        : undefined,
  };

  return {
    ...specialWords,
    period: coupon.period,
    period_unit: coupon.period_unit,
    dollar_amount: coupon.dollar_amount,
    percentage: coupon.percentage,
    starting_period: 0,
  } as Discount;
};

export const discountWording = (discount: Discount, short = false): string => {
  if (discount.in_words !== undefined) {
    return discount.in_words;
  }

  let inWords = "";
  if (discount?.percentage) {
    inWords = `${discount.percentage}%`;
  }

  if (discount?.dollar_amount) {
    inWords = `$${discount.dollar_amount / 100}`;
  }

  const startingPeriod = discount.starting_period === 0
    ? "1st"
    : ordinalSuffixOf(discount.starting_period);

  const periodUnit = discount.period_unit || "month";

  if (
    discount.period === 2
    && inWords === "100%"
    && startingPeriod === "7th"
    && periodUnit === "month"
  ) {
    return short
      ? "Two Months FREE"
      : "Two Months FREE - Your 7th and 13th Month on Us!";
  }

  if (
    inWords === "100%"
    && startingPeriod === "7th"
    && periodUnit === "month"
  ) {
    return short ? "One Month FREE" : "One Month FREE - Your 7th Month on Us!";
  }

  if (inWords !== "") {
    return `${inWords} ${discount?.period === 0
      ? `off your ${startingPeriod} ${periodUnit} subscription`
      : `off every ${periodUnit} for ${discount.period} ${periodUnit}s starting on the ${startingPeriod} ${discount.period_unit}`
    }`;
  }
  return "";
};

export interface Discount {
  period: number;
  period_unit: string;
  dollar_amount: number;
  percentage: string;
  starting_period: number;
  in_words?: string;
  title?: string;
  terms?: string;
}

export interface DiscountState {
  value: Discount;
  status: "idle" | "loading" | "failed";
  moveIn: boolean;
  referral: boolean;
}

const initialState: DiscountState = {
  value: {} as Discount,
  status: "idle",
  referral: false,
  moveIn: false,
};

export const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    setDiscount: (state, action: PayloadAction<Discount>) => {
      state.value = action.payload;
    },
    setStandardCampaignDiscount: (state) => {
      state.value = standardCampaignDiscount;
    },
  },
});

export const { setDiscount } = discountSlice.actions;

export const selectDiscount = (state: RootState) => state.discount.value;
export default discountSlice.reducer;

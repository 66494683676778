import React from 'react';
import {
} from 'react-bootstrap';
import { useAppSelector } from '../app/hooks';
import {
  selectSignUp, SignUP,
} from '../features/signup/signupSlice';
import { Plan } from '../models/plans';
import { Router } from '../models/routers';
import { selectAddress } from '../features/address/addressSlice';
import {
  selectBookingState,
} from '../features/bookings/bookingsSlice';
import {
  selectDiscount,
  discountWording,
  Discount,
} from '../features/discount/discountSlice';
import {
  pineAppleDay,
} from '../lib/utils';

export const GetMonthlyCost = (plan: Plan) => {
  const cost = parseFloat(plan.cost);
  return cost;
};

export const getDiscountPlanCost = (
  initialCost: number,
  discount?: Discount,
): number => {
  if (discount?.starting_period !== 0) return initialCost;

  if (discount?.percentage) {
    return (initialCost * (100 - parseFloat(discount.percentage))) / 100;
  }

  if (discount?.dollar_amount) {
    return (initialCost - (discount.dollar_amount / 100));
  }

  return initialCost;
};

export const GetTotalCost = (
  plan: Plan,
  router: Router,
  greenfield: boolean,
  discount?: Discount,
) => {
  const planCost = getDiscountPlanCost(GetMonthlyCost(plan), discount);
  let total = 0;
  if (planCost) {
    total += planCost;
  }
  if (router.cost) {
    total += parseFloat(router.cost);
  }
  if (greenfield) {
    total += 300;
  }
  return total;
};

export const summaryOpen = (signUpForm: SignUP) => !!(
  signUpForm.plan.id || signUpForm.router.id
);

export const planBuilt = (signUpForm: SignUP) => !!(
  signUpForm.plan.id && signUpForm.router.id
);

const OrderSummary = () => {
  const signUpForm = useAppSelector(selectSignUp);
  const address = useAppSelector(selectAddress);
  const bookingsState = useAppSelector(selectBookingState);
  const discount = useAppSelector(selectDiscount);

  const { plan, router } = signUpForm;

  if (!summaryOpen(signUpForm)) {
    return null;
  }

  const totalCost = GetTotalCost(
    plan,
    router,
    address.buildingCategory === 'greenfield',
    discount,
  );
  const monthlyCost = GetMonthlyCost(plan);
  const discountedMonthlyCost = getDiscountPlanCost(GetMonthlyCost(plan), discount);

  return (
    <div
      style={{ borderTop: '2px solid' }}
      className="
          px-2
          px-md-0
          py-2
          border-primary"
    >
      <h4>Your Order Summary</h4>
      <h6>PLAN DETAILS</h6>
      <ul className="list-unstyled">
        { plan.id ? (
          <li>
            Plan:
            {' '}
            <strong className="text-primary">
              { plan.name }
              {' '}
              { plan.speed }
              {' '}
              /
              {' '}
              { plan.speed }
              {' '}
              Mbps
            </strong>
            {' '}
            &mdash;
            {' '}
            <strong>
              { discount?.period !== 0
              && (monthlyCost !== discountedMonthlyCost)
                ? (
                  <span>
                    <span className="strikethrough">
                      $
                      { monthlyCost }
                    </span>
                    {' '}
                    $
                    { discountedMonthlyCost }
                  </span>
                )
                : (
                  <span>
                    $
                    { GetMonthlyCost(plan) }
                  </span>
                )}
            </strong>
            {' '}
            per month
            { discountWording(discount) !== ""
              ? (
                <>
                  <br />
                  <small
                    className="fw-bold
                    d-inline-block
                    bg-secondary
                    my-1
                    py-1
                    px-2
                    text-center
                    rounded-pill"
                  >
                    {discountWording(discount)}
                  </small>
                </>
              ) : null}
          </li>
        ) : null }
        { address.buildingCategory === 'greenfield'
          && (
          <li>
            Additional Charges:
            {' '}
            <strong className="text-primary">New Development</strong>
            {' – '}
            <strong>
              $300
              {' '}
            </strong>
            one off charge
          </li>
          )}
        { planBuilt(signUpForm)
          ? (
            <li>
              Total cost on activation:
              {' '}
              <strong>
                $
                {totalCost}
              </strong>
            </li>
          ) : null }
      </ul>
      { signUpForm.arrivy.customer_email ? (
        <>
          <h6 className="text-uppercase">Primary contact</h6>
          <ul className="list-unstyled">
            { signUpForm.arrivy.customer_first_name ? (
              <li>
                First name:
                {' '}
                <strong
                  className="text-primary"
                >
                  { signUpForm.arrivy.customer_first_name }
                </strong>
              </li>
            ) : null }
            { signUpForm.arrivy.customer_last_name ? (
              <li>
                Last name:
                {' '}
                <strong className="text-primary">
                  { signUpForm.arrivy.customer_last_name }
                </strong>
              </li>
            ) : null }
            { signUpForm.arrivy.customer_mobile_number ? (
              <li>
                Mobile:
                {' '}
                <strong className="text-primary">
                  { signUpForm.arrivy.customer_mobile_number }
                </strong>
              </li>
            ) : null }
            { signUpForm.arrivy.customer_email ? (
              <li>
                Email:
                {' '}
                <strong className="text-primary">
                  { signUpForm.arrivy.customer_email }
                </strong>
              </li>
            ) : null }
          </ul>
        </>
      ) : null }
      { signUpForm.arrivy.customer_address_line_2 ? (
        <>
          <h6 className="text-uppercase">Installation Address</h6>
          <ul className="list-unstyled">
            { signUpForm.arrivy.customer_address_line_1 ? (
              <li>
                Unit:
                {' '}
                <strong
                  className="text-primary"
                >
                  { signUpForm.arrivy.customer_address_line_1 }
                </strong>
              </li>
            ) : null }
            { signUpForm.arrivy.customer_address_line_2 ? (
              <li>
                Street number:
                {' '}
                <strong
                  className="text-primary"
                >
                  {signUpForm.arrivy.customer_address_line_2 }
                </strong>
              </li>
            ) : null }
            { address.suburb ? (
              <li>
                Suburb:
                {' '}
                <strong
                  className="text-primary"
                >
                  { address.suburb }
                </strong>
              </li>
            ) : null }
            { address.postcode ? (
              <li>
                Postcode:
                {' '}
                <strong
                  className="text-primary"
                >
                  { address.postcode }
                </strong>
              </li>
            ) : null }
            { address.state ? (
              <li>
                State:
                {' '}
                <strong
                  className="text-primary"
                >
                  { address.state }
                </strong>
              </li>
            ) : null }
          </ul>
        </>
      ) : null }
      { bookingsState.activeBookingId.start ? (
        <>
          <h6 className="text-uppercase">Activation Date</h6>
          <ul className="list-unstyled">
            <li>
              Day:
              {' '}
              <strong
                className="text-primary"
              >
                { pineAppleDay(new Date(bookingsState.activeBookingId.start)) }
              </strong>
            </li>
          </ul>
        </>
      ) : null }
    </div>
  );
};

export default OrderSummary;

import React, { useState } from 'react';
import {
  Stack, Container, Row, Col, Button,
} from 'react-bootstrap';
import AddressInput from './AddressInput';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  setAddressUniqueCodeAndUnit,
} from '../features/signup/signupSlice';
import { selectAddress } from '../features/address/addressSlice';
import OrderSummary from './OrderSummary';

export interface DetailProps {
  onSubmit: any
  previousStep: any
  formState: any
  register: any
  handleSubmit: any
}

const AddressDetails = ({
  onSubmit, previousStep, formState, register, handleSubmit,
}: DetailProps) => {
  const dispatch = useAppDispatch();
  const addr = useAppSelector(selectAddress);
  const [unitCorrected, setUnitCorrected] = useState("");

  const next = handleSubmit((values: any) => {
    if (String(values?.unit).match(/^(U|u)\D*[0-9]+/)) {
      const correctedUnit = String(values.unit).replace(/^(U|u)\D*/, "");
      setUnitCorrected(correctedUnit);

      dispatch(setAddressUniqueCodeAndUnit({
        unit: correctedUnit,
        uniqueCode: addr.uniqueCode,
      }));
      onSubmit();
    } else {
      dispatch(setAddressUniqueCodeAndUnit({
        unit: values.unit,
        uniqueCode: addr.uniqueCode,
      }));

      onSubmit();
    }
  });

  return (
    <Container>
      <Row className="justify-content-md-center mb-4">
        <Col>
          <h1 className="fw-bold text-primary text-center">
            Address to be connected
          </h1>
        </Col>
      </Row>
      <Row className="position-relative">
        <Col md={8}>
          <AddressInput formState={formState} register={register} />
        </Col>
        <Col>
          <OrderSummary />
        </Col>
      </Row>
      <Stack
        gap={2}
        className="justify-content-center my-4"
        direction="horizontal"
      >
        <Button
          id="connection_details_go_back"
          onClick={previousStep}
          size="lg"
          variant="outline-light"
        >
          <span className="text-body px-2">Go back</span>
        </Button>
        <Button
          id="connection_details_continue"
          onClick={next}
          size="lg"
          variant="primary"
        >
          <span className="px-2">Continue</span>
        </Button>
      </Stack>
    </Container>
  );
};

export default AddressDetails;

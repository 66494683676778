import React, { useState, useEffect } from 'react';
import {
  Card, ListGroup, Button, Row, Col,
} from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setPlan, selectSignUp } from '../features/signup/signupSlice';
import { Plan } from '../models/plans';
import ScrollIndicator from './ScrollIndicator';
import ScrollStack from './ScrollStack';

interface PlanProps {
  plans: Record<string, Plan>
  className?: string
}

const Plans = ({ className = '', plans }: PlanProps) => {
  const dispatch = useAppDispatch();
  const signUpState = useAppSelector(selectSignUp);

  // eslint-disable-next-line max-len
  const criticalInfoLink = 'https://www.pineapple.net.au/wp-content/uploads/PN_ResCIS.pdf';

  const plansState: Record<string, boolean> = Object
    .keys(plans)
    .reduce((acc, name) => ({
      ...acc,
      [name]: signUpState.plan.name === name,
    }), {});

  const sortedPlans = Object.values(plans)
    .sort((p1, p2) => p1.speed - p2.speed);

  const defaultPlan = () => sortedPlans
    .find((plan) => plan.name === 'Boost 100');

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const [
    buttonState,
    setButtonState,
  ] = useState<Record<string, boolean>>(plansState);
  const setChange = (plan: string) => {
    Object.keys(buttonState).forEach((planName) => {
      buttonState[planName] = false;
    });
    buttonState[plan] = !buttonState[plan];
    setButtonState({
      ...buttonState,
    });
    setSelectedIndex(sortedPlans.map((p) => p.name).indexOf(plan));
    dispatch(setPlan(plans[plan]));
  };

  // If the default plan changes such as through loading a different set of
  // sorted plans, trigger this effect again
  useEffect(() => {
    const p = defaultPlan();
    if (p !== undefined) setChange(p.name);
  }, [defaultPlan()]);

  return (
    <div className={className}>
      <ScrollStack
        className="hide-scrollbar
          px-2
          py-3
          overflow-scroll
          d-flex
          align-items-stretch
          "
        gap={2}
        direction="horizontal"
        initialPosition="offset-1"
      >
        {sortedPlans.map((plan) => (
          <Card
            key={plan.id}
            className="pineapple-card shadow h-100"
            border="grey"
          >
            <Card.Body>
              <Card.Title
                className="
                  text-center
                  text-primary
                  border-bottom
                  border-primary
                  fs-3
                  pb-2
                  mb-0"
              >
                {plan.name}
                <br />
                {plan.speed}
                /
                {plan.speed}
                {' '}
                Mbps *
              </Card.Title>
              <ListGroup
                className="border-bottom border-primary py-2"
                variant="flush"
              >
                <ListGroup.Item
                  className="border-0 p-0 position-relative align-middle"
                >
                  <CheckCircleFill
                    style={{
                      backgroundColor: '#03793E',
                      borderRadius: '1em',
                    }}
                    className="
                      position-absolute
                      start-0
                      top-0
                      bottom-0
                      m-auto"
                    color="#F5E748"
                  />
                  <strong
                    className="ms-3"
                  >
                    No lock in contract
                  </strong>
                </ListGroup.Item>
                <ListGroup.Item
                  className="border-0 p-0 position-relative align-middle"
                >
                  <CheckCircleFill
                    style={{
                      backgroundColor: '#03793E',
                      borderRadius: '1em',
                    }}
                    className="
                      position-absolute
                      start-0
                      top-0
                      bottom-0
                      m-auto"
                    color="#F5E748"
                  />
                  <strong
                    className="ms-3"
                  >
                    Unlimited data
                  </strong>
                </ListGroup.Item>
                <ListGroup.Item
                  className="border-0 p-0 position-relative align-middle"
                >
                  <CheckCircleFill
                    style={{
                      backgroundColor: '#03793E',
                      borderRadius: '1em',
                    }}
                    className="
                      position-absolute
                      start-0
                      top-0
                      bottom-0
                      m-auto"
                    color="#F5E748"
                  />
                  <strong
                    className="ms-3"
                  >
                    Equal up and download speeds
                  </strong>
                </ListGroup.Item>
              </ListGroup>
              <Card.Text className="text-center py-2 mb-0" />
              <Card.Text className="text-center">
                <span
                  className="
                  display-1
                  text-primary
                  text-center
                  lh-1
                  d-block"
                >
                  $
                  {plan.cost}
                  <span
                    className="text-primary text-center align-top display-3"
                  >
                    ^
                  </span>
                </span>
                <span className="fs-6">per month</span>
                <br />
                <span
                  className="fs-6"
                >
                  {plan.description1
                  && plan.description1 !== undefined
                  && plan.description1 !== '  '
                    ? plan.description1
                    : <span>&nbsp;&nbsp;</span>}
                </span>
              </Card.Text>
              <Button
                id={`select_plan_${plan.name}`}
                className="w-100"
                size="lg"
                onClick={() => setChange(plan.name)}
                variant={buttonState[plan.name] ? 'secondary' : 'primary'}
              >
                { buttonState[plan.name] ? 'Selected' : 'Select' }
              </Button>
            </Card.Body>
          </Card>
        ))}
      </ScrollStack>
      <Row className="justify-content-sm-center">
        <Col sm md={4} className="justify-content-center d-flex">
          <ScrollIndicator
            activeIndex={selectedIndex}
            length={Object.values(plans).length}
          />
        </Col>
      </Row>
      <Row className="justify-content-sm-center">
        <Col sm md={6}>
          <ul
            style={{ fontSize: '0.9rem' }}
            className="px-2 list-unstyled text-center"
          >
            <li>
              <strong className="text-primary">*</strong>
              {' '}
              <a
                href={criticalInfoLink}
                target="_blank"
                rel="noreferrer"
              >
                Critical information
              </a>
            </li>
            <li>
              <strong className="text-primary">*</strong>
              {' '}
              Typical evening speeds are subject to change and are measured
              between
              7pm - 11pm. Speeds are not guaranteed and may vary.
            </li>
            <li>
              <strong className="text-primary">^</strong>
              {' '}
              Special offer pricing only available to customers switching from Rapid-Net to Pineapple Net.
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Plans;

import dayjs from "dayjs";
import httpClient from "../../http/client";
import { backendURL } from "../../lib/config";
import { ArrivyBooking, ArrivyTemplateExtraFields } from "../../models/arrivy";
import { ActiveBooking } from "../bookings/bookingsSlice";
import { SignUP } from "../signup/signupSlice";

export interface BookingRequest {
  payload: ArrivyBooking;
  booking: ActiveBooking;
  signUp: SignUP;
}

export const createInstallation = ({
  payload,
  booking,
  signUp,
}: BookingRequest) => {
  const templateExtraFields: Array<ArrivyTemplateExtraFields> = [];
  try {
    Object.keys(signUp.arrivyExtraFields).forEach((key) => {
      templateExtraFields.push(signUp.arrivyExtraFields[key]);
    });
  } catch (err) {
    console.log("type error", err);
    throw err;
  }

  const payloadCombined: ArrivyBooking = {
    ...payload,
    template_extra_fields: templateExtraFields,
    title: payload.title,
    start_datetime: booking.start,
    end_datetime: booking.end,
  };

  // Subscription should start at end of installation day
  const subscriptionStart = dayjs(booking.start).endOf("day").valueOf();

  const planId = signUp.plan.id;
  return httpClient.post(`${backendURL}/api/v1/pineapple/install`, {
    event_id: `${booking.event_id}`,
    arrivy_booking: payloadCombined,
    rapidnet: true,
    charge_bee: {
      start_date: subscriptionStart,
      first_name: payload.customer_first_name,
      last_name: payload.customer_last_name,
      unit: `${payload.customer_address_line_1} `,
      address: payload.customer_address_line_2,
      state: payload.customer_state,
      email: payload.customer_email,
      postcode: payload.customer_zipcode,
      phone: payload.customer_mobile_number,
      suburb: payload.customer_city,
      plan_id: planId,
      tmp_token: signUp.cb_token,
      coupon_id: signUp.coupon?.coupon_id,
      coupon_type: signUp.coupon?.coupon_type,
      service_id: signUp.arrivy.title,
      router_charge: signUp.router.cost,
      add_ons: signUp.addOns,
    },
  });
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { BookingRequest, createInstallation } from './finaleAPI';

export interface Finale {
  discountDollar: number;
  discountPercentage: number;
  success: boolean;
  err: string;
}

export interface FinaleState {
  value: Finale;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: FinaleState = {
  value: {} as Finale,
  status: 'idle',
};

const parseError = (payload: any) => {
  try {
    return JSON.parse(payload.error).message;
  } catch {
    return 'Something went wrong';
  }
};

export const postBookingsAsync = createAsyncThunk(
  'finale/creaetBooking',

  async (req: BookingRequest, thunkAPI) => {
    const resp = await createInstallation(req);
    if (resp.status >= 299) {
      return thunkAPI.rejectWithValue(resp.data);
    }

    return resp.data;
  },
);

export const finaleSlice = createSlice({
  name: 'finale',
  initialState,
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(postBookingsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postBookingsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.err = '';
        state.value.success = true;
      })
      .addCase(postBookingsAsync.rejected, (state, action: any) => {
        state.status = 'failed';
        state.value.err = parseError(action.payload);
        state.value.success = false;
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectFinaleState = (state: RootState) => state.finale;
export default finaleSlice.reducer;

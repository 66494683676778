import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { fetchBookings, Booking, fetchBooking } from "./bookingsAPI";

export interface ActiveBooking {
  start: number;
  end: number;
  event_id: number;
  title: string;
}

export interface BookingsState {
  value: Array<Booking>;
  activeBookingId: ActiveBooking;
  status: "idle" | "loading" | "bookingLoading" | "failed";
}

export const initialState: BookingsState = {
  value: [],
  activeBookingId: {} as ActiveBooking,
  status: "idle",
};

export interface FetchDateArgs {
  state: string;
  date: Date;
}

export const fetchBookingsAsync = createAsyncThunk(
  "bookings/fetchBookings",
  async ({ state, date }: FetchDateArgs) => {
    const response = await fetchBookings(state, date);
    return response.data;
  },
);

export const fetchBookingAsync = createAsyncThunk(
  "bookings/fetchBooking",
  async ({ state, date }: FetchDateArgs) => {
    const response = await fetchBooking(state, date);
    return response.data;
  },
);

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setActiveBookingId: (state, action: PayloadAction<ActiveBooking>) => {
      console.log(action.payload);
      state.activeBookingId = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBookingsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = [...state.value, ...action.payload.booking_slots];
      })
      .addCase(fetchBookingsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchBookingAsync.pending, (state) => {
        state.status = "bookingLoading";
      })
      .addCase(fetchBookingAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = state.value.map((val) => {
          action.payload.booking_slots.forEach((slot) => {
            if (
              val.start_datetime_original_iso_str
              === slot.start_datetime_original_iso_str
            ) {
              val.booking_id = slot.booking_id;
            }
          });
          return val;
        });
      })
      .addCase(fetchBookingAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
export const selectBookings = (state: RootState) => state.bookings.value;
export const selectActiveBookingId = (state: RootState) => state.bookings.activeBookingId;
export const selectBookingState = (state: RootState) => state.bookings;

export const { setActiveBookingId } = bookingsSlice.actions;
export default bookingsSlice.reducer;

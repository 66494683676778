import React, { useEffect, useState, useCallback } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import enAU from 'date-fns/locale/en-AU/index';
import {
  Container, Col, Row, Spinner, Stack, Button,
} from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  fetchBookingsAsync,
  selectActiveBookingId, selectBookings, selectBookingState, setActiveBookingId,
} from '../features/bookings/bookingsSlice';
import './react-datepicker.css';
import { selectAddress } from '../features/address/addressSlice';
import {
  filterDuplicates,
  pineAppleDay,
} from '../lib/utils';
import { fetchBooking } from '../features/bookings/bookingsAPI';
import { PickerBooking } from '../models/picker_booking';

registerLocale('en-AU', enAU);

export interface Errors {
  error: boolean
  errText: string
}

export interface PickerProps {
  onSubmit: any
  previousStep: any
}

const makeDateString = (date: Date): string => `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

const Picker = ({ onSubmit, previousStep }: PickerProps) => {
  const dispatch = useAppDispatch();
  const addr = useAppSelector(selectAddress);
  const bookings = useAppSelector(selectBookings);
  const bookingsState = useAppSelector(selectBookingState);
  const activeBooking = useAppSelector(selectActiveBookingId);

  const today = new Date();

  const getMoreBookings = useCallback((from: Date) => {
    if (bookings.length === 0) {
      dispatch(fetchBookingsAsync({ state: addr.state, date: from }));
    }
  }, [bookings]);

  // Get initial bookings
  useEffect(() => {
    getMoreBookings(today);
  }, []);

  const EVENTS: Array<PickerBooking> = filterDuplicates(bookings);

  const [err, setErr] = useState('');
  const [timeLoading, setTimeLoading] = useState(false);

  const onChange = async (date: Date) => {
    setTimeLoading(true);

    await fetchBooking(addr.state, date).then((resp) => {
      const slots = resp.data.booking_slots;
      if (slots.length === 0) return;
      const slot = slots[Math.floor(Math.random() * slots.length)];
      dispatch(setActiveBookingId({
        event_id: slot.id,
        start: dayjs(slot.start_datetime_original_iso_str).toDate().getTime(),
        end: dayjs(slot.end_datetime_original_iso_str).toDate().getTime(),
        title: '',
      }));
    }).catch((error) => {
      console.log('ERROR loading bookings', error);
      setErr(error);
    });
    setTimeLoading(false);
  };

  const submitHandle = () => {
    if (!activeBooking.event_id || activeBooking.event_id === undefined) {
      setErr('Please select an activation date.');
    } else {
      setErr('');
      onSubmit();
    }
  };

  if (bookingsState.status === 'loading') {
    return (
      <div className="center">
        <Spinner
          style={{ width: '6rem', height: '6rem' }}
          animation="border"
          variant="primary"
        />
        <h1 className="fw-bold text-primary text-center px-5">
          {' '}
          Loading available bookings
        </h1>
      </div>
    );
  }
  return (
    <Container fluid="xl">
      <Row className="mb-2 justify-content-center">
        <Col xs={12}>
          <h1 className="fw-bold text-primary text-center">
            Select your Activation Date
          </h1>
          <p className="lead text-center text-center px-md-5">
            This is the date your internet connection will be activated with
            Pineapple Net.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center mb-5">
        <Col>
          <DatePicker
            monthsShown={2}
            onChange={onChange}
            selected={activeBooking.start ? new Date(activeBooking.start) : false}
            openToDate={today}
            inline
            locale="en-AU"
            calendarStartDay={1}
            includeDates={EVENTS.flatMap((e) => (e.start.getTime() <= Date.parse("2024-06-30") ? e.start : undefined))}
            renderCustomHeader={({
              monthDate,
            }: any) => (
              <div className="react-datepicker__current-month">
                {format(monthDate, 'MMMM')}
                {' '}
                {monthDate.getFullYear()}
              </div>
            )}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto" className="text-success">
          { timeLoading === true && (
          <div className="time-spinner">
            <Spinner
              style={{ width: '2rem', height: '2rem' }}
              animation="border"
              variant="primary"
            />
            <h4 className="text-primary text-center px-2">
              {' '}
              Confirming activation date
            </h4>
          </div>
          )}
          {activeBooking?.event_id && timeLoading === false
            ? (
              <h4 className="font-bold text-body text-center">
                You have selected
                {' '}
                {pineAppleDay(new Date(activeBooking.start))}
              </h4>
            )
            : <h4 className="text-info">{err}</h4>}
        </Col>
      </Row>
      <Stack
        gap={2}
        className="justify-content-center my-4"
        direction="horizontal"
      >
        <Button
          id="installation_go_back"
          onClick={previousStep}
          size="lg"
          variant="outline-light"
        >
          <span className="text-body px-2">Go back</span>
        </Button>
        <Button
          id="installation_continue"
          onClick={submitHandle}
          size="lg"
          variant="primary"
        >
          <span className="px-2">Continue</span>
        </Button>
      </Stack>
    </Container>
  );
};

export default Picker;

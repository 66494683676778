import httpClient from "../../http/client";
import { arrivyBookingID } from "../../lib/config";

export type Booking = {
  updated: string;
  is_disabled: boolean;
  start_datetime: string;
  end_datetime: string;
  end_datetime_original_iso_str: string;
  id: number;
  booking_id: number;
  url_safe_id: string;
  start_datetime_original_iso_str: string;
};

type AddrResponse = {
  booking_slots: Array<Booking>;
};

export function fetchBookings(state: string, date: Date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth(), 30);

  const isoDateTime = new Date(
    firstDay.getTime() - firstDay.getTimezoneOffset() * 60000,
  ).toISOString();
  const fr = encodeURIComponent(isoDateTime);

  const isoDateTimeToDate = new Date(
    lastDay.getTime() - lastDay.getTimezoneOffset() * 60000,
  ).toISOString();
  const to = encodeURIComponent(isoDateTimeToDate);

  return httpClient.get<AddrResponse>(
    `https://app.arrivy.com/api/public/bookings/v2/${arrivyBookingID.RAPIDNET}?company_id=&from=${fr}&to=${to}&use_booking_setting_timezone=true&exact_location=null`,
    {
      headers: {
        Accept: "application/json",
        "Accept-Encoding": "gzip, deflate, br",
      },
    },
  );
}

export function fetchBooking(state: string, date: Date) {
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
  );
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
  );

  const isoDateTime = firstDay.toISOString();
  const fr = encodeURIComponent(isoDateTime);

  const isoDateTimeToDate = new Date(
    lastDay.getTime() - lastDay.getTimezoneOffset() * 60000,
  ).toISOString();

  const to = encodeURIComponent(isoDateTimeToDate);

  return httpClient.get<AddrResponse>(
    `https://app.arrivy.com/api/public/bookings/${arrivyBookingID.RAPIDNET}?company_id=&from=${fr}&to=${to}&use_booking_setting_timezone=true&exact_location=null`,
    {
      headers: {
        Accept: "application/json",
        "Accept-Encoding": "gzip, deflate, br",
      },
    },
  );
}

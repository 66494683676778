import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Form from './components/Form';
import Nav from './components/Nav';
import {
  setFormattedAddressFromUrl,
} from './features/signup/signupSlice';
import {
  setAddressFromUrl,
} from './features/address/addressSlice';
import { FormValues } from './lib/errors';
// import {
//   ActiveBooking,
//   fetchBookingsAsync,
//   selectBookings,
//   setActiveBookingId,
// } from './features/bookings/bookingsSlice';

export const PNWebsiteBaseURL = process.env.REACT_APP_ENV === 'production'
  ? 'https://pineapple.net.au' : 'http://new.master.pineapple.net.au';

const App = () => {
  const dispatch = useAppDispatch();
  // const bookings = useAppSelector(selectBookings);

  // @ts-ignore
  window.Chargebee.init({
    site: process.env.REACT_APP_ENV === 'production'
      ? 'pineapple' : 'pineapple-test',
    publishableKey: process.env.REACT_APP_ENV === 'production'
      ? 'live_G23tMxUiAVBKNyBpF4UcdNQkJYJBjNvN'
      : 'test_MBszcxZ7HRb3cdo8FAHDdLXn49fi2eRem',
  });

  const {
    register, handleSubmit, formState, getValues,
  } = useForm<FormValues>({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
  });

  const addrForm = useForm<FormValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const parsed = queryString.parse(window.location.search);
  let { uniqueCode } = parsed;
  if (uniqueCode === null || uniqueCode === undefined) {
    uniqueCode = '';
  }

  // useEffect(() => {
  //   dispatch(fetchBookingsAsync({ state: 'VIC', date: new Date() }));
  // }, []);

  // useEffect(() => {
  //   const b = bookings.slice(0, 100)[Math.floor(Math.random() * 100)];
  //   if (b === undefined) return;
  //   const activeBooking = {
  //     start: dayjs(b.start_datetime_original_iso_str).toDate().getTime(),
  //     end: dayjs(b.end_datetime_original_iso_str).toDate().getTime(),
  //     event_id: b.id,
  //     title: '',
  //   } as ActiveBooking;
  //   dispatch(setActiveBookingId(activeBooking));
  // }, [bookings]);

  useEffect(() => {
    // @ts-ignore
    if (uniqueCode !== ''
      && parsed.formatted_address
      && parsed.street_number && parsed.postcode
      && parsed.suburb && parsed.street
      && parsed.status && parsed.state
      && parsed.buildingClass && parsed.buildingCategory) {
      dispatch(setFormattedAddressFromUrl({
        // @ts-ignore
        formatted_address: parsed.formatted_address,
        // @ts-ignore
        street: parsed.street,
        // @ts-ignore
        street_number: parsed.street_number,
        // @ts-ignore
        state: parsed.state,
        // @ts-ignore
        suburb: parsed.suburb,
        // @ts-ignore
        postcode: parsed.postcode,
        // @ts-ignore
        uniqueCode: parsed.uniqueCode,
        // @ts-ignore
        status: parsed.status,
        // @ts-ignore
        mdu: parsed?.buildingClass?.toLowerCase().includes('mdu'),
        // @ts-ignore
        commercial: parsed?.buildingType?.toLowerCase().includes('commercial'),
      }));
      // @ts-ignore
      dispatch(setAddressFromUrl({
        // @ts-ignore
        formatted_address: parsed.formatted_address,
        // @ts-ignore
        street: parsed.street,
        // @ts-ignore
        street_number: parsed.street_number,
        // @ts-ignore
        state: parsed.state,
        // @ts-ignore
        suburb: parsed.suburb,
        // @ts-ignore
        postcode: parsed.postcode,
        // @ts-ignore
        uniqueCode: parsed.uniqueCode,
        // @ts-ignore
        status: parsed.status,
        // @ts-ignore
        commercial: parsed?.buildingType?.toLowerCase().includes('commercial'),
        // @ts-ignore
        buildingCategory: parsed.buildingCategory,
      }));
    }
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="viewport"
          content={
          `width=device-width,
          minimum-scale=1.0,
          maximum-scale = 1.0,
          user-scalable = no`
          }
        />
      </Helmet>
      <Nav />
      <Form
        // @ts-ignore
        uniqueCode={uniqueCode}
        register={register}
        handleSubmit={handleSubmit}
        formState={formState}
        getValues={getValues}
        addrForm={addrForm}
      />
    </HelmetProvider>
  );
};

export default App;

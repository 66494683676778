import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchPlans } from "./plansAPI";
import { Plan } from "../../models/plans";

export interface PlansState {
  value: Array<Plan>;
  status: "idle" | "loading" | "failed";
}

const initialState: PlansState = {
  value: [],
  status: "idle",
};

export const fetchPlansAsync = createAsyncThunk(
  "plans/fetchPlans",
  async (buildingCode: string) => {
    await fetchPlans(buildingCode);
    // The value we return becomes the `fulfilled` action payload
    return [
      {
        id: "pa-250",
        name: "Boost",
        rsp: "pineapple",
        description1: "For 9 months then $79 ongoing",
        description2: "79",
        speed: 250,
        cost: "59",
        addressLIT: true,
        addressNonLIT: false,
        published: true,
      },
    ];
  },
);

export const plansSlice = createSlice({
  name: "plans",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlansAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlansAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload;
      })
      .addCase(fetchPlansAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPlans = (state: RootState) => state.plans;
export default plansSlice.reducer;

import React, { useEffect, useState } from 'react';
import {
  Col, Row, Container, Button,
} from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectAddress } from '../features/address/addressSlice';
import Plans from './Plans';
import Routers from './Routers';
import { selectSignUp, addAddOn } from '../features/signup/signupSlice';
import { Plan } from '../models/plans';
import { Router } from '../models/routers';
import {
  getDiscountPlanCost,
  GetMonthlyCost,
  GetTotalCost,
  summaryOpen,
  planBuilt,
} from './OrderSummary';
import { fetchPlansAsync, selectPlans } from '../features/plans/plansSlice';
import {
  fetchRoutersAsync,
  selectRouters,
} from '../features/routers/routersSlice';
import {
  selectDiscount,
  discountWording,
} from '../features/discount/discountSlice';

export interface PlanBuilderProps {
  onSubmit: any;
  previousStep: any;
}

// Different address will have different sets of plans displayed to them
const includePlanFromAddressState = (
  addressStatus: string,
  greenfield: boolean,
  includeIfLIT: boolean,
  includeIfNotLIT: boolean,
) => {
  // Should greenfields show all the plans? I suppose so.
  if ((addressStatus === 'LIT' || greenfield) && includeIfLIT) return true;
  if ((addressStatus !== 'LIT' && !greenfield) && includeIfNotLIT) return true;
  return false;
};

const PlanBuilder = ({ onSubmit, previousStep }: PlanBuilderProps) => {
  const [plans, setPlans] = useState<Record<string, Plan>>({});
  const [routers, setRouters] = useState<Record<string, Router>>({});
  const dispatch = useAppDispatch();
  const addr = useAppSelector(selectAddress);
  const signUp = useAppSelector(selectSignUp);
  const plansState = useAppSelector(selectPlans);
  const routersState = useAppSelector(selectRouters);
  const discount = useAppSelector(selectDiscount);

  useEffect(() => {
    dispatch(fetchPlansAsync(addr.uniqueCode));
    if (addr.buildingCategory === 'greenfield') dispatch(addAddOn('new-development-fee'));
  }, [addr]);
  useEffect(() => {
    if (plansState.value) {
      setPlans(plansState.value.reduce((acc, p) => {
        if (p.rsp !== 'pineapple') return acc;
        if (
          !includePlanFromAddressState(
            addr.status,
            (addr.buildingCategory === 'greenfield'),
            p.addressLIT,
            p.addressNonLIT,
          )
        ) return acc;
        return { ...acc, [p.name]: p };
      }, {} as Record<string, Plan>));
    }
  }, [plansState.value]);

  useEffect(() => { dispatch(fetchRoutersAsync()); }, []);
  useEffect(
    () => setRouters(routersState.value.reduce((acc, p) => {
      if (p.rsp !== 'pineapple') return acc;
      return { ...acc, [p.id]: p };
    }, {} as Record<string, Router>)),
    [routersState.value],
  );

  return (
    <Container>
      <Row className="mb-4">
        <Col className="d-flex flex-column">
          <h1 className="fw-bold text-primary text-center">
            Select your plan
          </h1>
          <Plans plans={plans} />
        </Col>
      </Row>
      <Row className="mb-4 d-none">
        <Col className="d-flex flex-column">
          <h1 className="fw-bold text-primary text-center">
            Choose a router
          </h1>
          <p className="lead text-center col-md-8 align-self-center">
            We recommend including a Pineapple Net router which will deliver
            optimum performance, it will also allow our technical team to
            assist with any remote support. The below router may be subject
            to change due to availability. If unavailable an equivalent WI-FI 6
            router will be supplied.
          </p>
          <Routers routers={routers} />
        </Col>
      </Row>

      {addr.buildingCategory === "greenfield"
      && (
      <Row className="mb-4">
        <Col className="d-flex flex-column">
          <h1 className="fw-bold text-primary text-center">
            New development charge
          </h1>
          <p className="lead text-center col-md-8 align-self-center">
            As your building is a new development a one-off New Development Charge of $300
            will be applied to your account to cover the cost of connecting your premises
            to DGtek Fibre Optic Network. This is a commonly adopted charge for new
            buildings that is passed through by Telecommunications operators.
          </p>
        </Col>
      </Row>
      )}

      { summaryOpen(signUp) ? (
        <Row className="justify-content-md-center mt-4">
          <Col lg={6}>
            <h1
              className="fw-bold text-primary text-center"
            >
              Your order summary
            </h1>
            <p className="lead text-center mb-4">
              Below is a summary of what you have selected so far.
              <br />
              To progress with your order simply click on Add to Cart to set up
              your&nbsp;account.
            </p>
          </Col>
        </Row>
      ) : null }

      { signUp.plan.id ? (
        <Row
          style={{ borderTop: '2px solid' }}
          className="border-primary py-2"
        >
          <Col md={2} className="px-md-0 d-flex align-items-center">
            <span>Plan</span>
          </Col>
          <Col xs={8} className="px-md-0 d-flex align-items-center">
            <span>
              <span className="text-primary fs-2 fw-bold">
                { signUp.plan.name }
                {' '}
                {signUp.plan.speed}
                {' '}
                /
                {' '}
                {signUp.plan.speed}
                {' '}
                Mbps
              </span>
              <br />
              <span className="text-capitalize fs-3 fw-bold">
                { discountWording(discount, true) !== "" && `Plus ${discountWording(discount, true)}` }
              </span>
            </span>
          </Col>
          <Col
            className="px-md-0 d-flex align-items-center justify-content-end"
          >
            <div className="text-end">
              <span className="fs-2 fw-bold d-block">
                $
                {discount?.period !== 0 ? getDiscountPlanCost(GetMonthlyCost(signUp.plan), discount) : signUp.plan.cost}
              </span>
              Per month
            </div>
          </Col>
        </Row>
      ) : null }

      { addr.buildingCategory === 'greenfield'
        && (
        <Row
          style={{ borderTop: '2px solid' }}
          className="border-primary py-2"
        >
          <Col md={2} className="invisible d-none d-md-inline-block px-0">
            <div className="text-end">
              <span className="fs-2 fw-bold d-block">
                $0
              </span>
              000
            </div>
          </Col>
          <Col xs={8} className="px-md-0 d-flex align-items-center">
            <span
              className="text-primary fs-2 fw-bold"
            >
              New Development Charge
            </span>
          </Col>
          <Col
            className="px-md-0 d-flex align-items-center justify-content-end"
          >
            <div className="text-end">
              <span className="fs-2 fw-bold d-block">
                $300
              </span>
              one off charge
            </div>
          </Col>
        </Row>
        )}

      { planBuilt(signUp) ? (
        <Row
          style={{ borderTop: '2px solid', borderBottom: '2px solid' }}
          className="border-primary py-2"
        >
          <Col md={2} className="invisible d-none d-md-inline-block px-0">
            <div className="text-end">
              <span className="fs-2 fw-bold d-block">
                $0
              </span>
              000
            </div>
          </Col>
          <Col className="px-md-0 d-flex align-items-center">
            <span
              className="text-primary fw-bold fs-2"
            >
              Total charge on activation
            </span>
          </Col>
          <Col
            className="px-md-0 d-flex align-items-center justify-content-end"
          >
            <div className="text-end">
              <span className="fs-2 fw-bold d-block">
                $
                { GetTotalCost(
                  signUp.plan,
                  signUp.router,
                  addr.buildingCategory === 'greenfield',
                  discount,
                ) }
              </span>
            </div>
          </Col>
        </Row>
      ) : null }
      <Row className="justify-content-md-center my-4 my-md-5">
        <Col className="order-3 order-md-1" md={3}>
          <Button
            className="w-100 my-1 my-md-0"
            onClick={previousStep}
            size="lg"
            variant="outline-light"
            id="build_your_plan_go_back"
          >
            <span className="text-body">Go back</span>
          </Button>
        </Col>
        <Col className="order-2" md={3}>
          <Button
            className="w-100 my-1 my-md-0"
            onClick={onSubmit}
            size="lg"
            variant="primary"
            disabled={!planBuilt(signUp)}
            id="build_your_plan_add_to_cart"
          >
            Add to cart
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PlanBuilder;

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import addressReducer from "../features/address/addressSlice";
import campaignReducer from "../features/campaign/campaignSlice";
import discountReducer from "../features/discount/discountSlice";
import plansReducer from "../features/plans/plansSlice";
import routersReducer from "../features/routers/routersSlice";
import signupReducer from "../features/signup/signupSlice";
import bookingsReducer from "../features/bookings/bookingsSlice";
import finaleReducer from "../features/finale/finaleSlice";

export const store = configureStore({
  reducer: {
    address: addressReducer,
    campaign: campaignReducer,
    discount: discountReducer,
    plans: plansReducer,
    routers: routersReducer,
    signup: signupReducer,
    bookings: bookingsReducer,
    finale: finaleReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import React, { useState, useCallback, useEffect } from 'react';
import {
  Button, Container, Stack, Row, Col, Modal,
} from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import dayjs from 'dayjs';
import AddressSearch from '@pineapple/address-search';
import type { Address } from '@pineapple/address-search';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectAddress,
  setAddress,
  emptyAddress,
} from '../features/address/addressSlice';
import {
  setFormattedAddress,
} from '../features/signup/signupSlice';
import ExpressInterest from './ExpressInterest';
import PineappleModal from './PineappleModal';

export interface CheckAddressProps {
  onSubmit: any;
}

const CheckAddress = ({ onSubmit }: CheckAddressProps) => {
  const dispatch = useAppDispatch();
  const addr = useAppSelector(selectAddress);

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [closed, setClosed] = useState<boolean>(false);
  const [showExpressInterest, setShowExpressInterest] = useState<boolean>(false);

  const computeSuccess = (address: Address) => [
    'LIT',
    'ComingSoon',
    'BuildCommenced',
  ].includes(address.status);

  // Respond to address changing and submitted the form so as not to race set
  // state with events dispatching through the system
  useEffect(() => {
    if (addr !== emptyAddress) {
      setSuccess(computeSuccess(addr));
    }
  }, [addr, submitted]);

  useEffect(() => {
    if (success === true) {
      onSubmit();
    }
  }, [success]);

  const handleAddressSearchResult = useCallback((address: Address) => {
    dispatch(setFormattedAddress(address));
    dispatch(setAddress(address));
    setSubmitted(true);
  }, []);

  const handleClose = useCallback(() => {
    setSubmitted(false);
    setSuccess(false);
    setClosed(true);
  }, [addr]);

  const form = (
    <>
      <Fade timeout={200} in>
        <Container className="text-center mt-5 fade-in">
          <Row className="justify-content-md-center">
            <Col md={8}>
              <h1 className="display-2 fw-bold text-primary">
                <span className="text-black">Welcome to </span>
                Pineapple Net!
              </h1>
              <p className="lead text-black">
                Thank you for choosing to switch your internet service to Pineapple Net. We
                understand that switching to another internet provider can be daunting. We’re
                committed to making this process as smooth as possible for you, with no
                interruption to your service.
              </p>
            </Col>
          </Row>
        </Container>
      </Fade>
      <Container style={{ flexGrow: 1 }} className="mb-md-5 d-flex align-items-center justify-content-center">
        <Row className="align-items-center justify-content-center">
          <Col md={7}>
            <Stack direction="vertical" gap={1}>
              <h1 className="text-center text-primary fw-bold">
                Let&apos;s get you switched to Pineapple Net
              </h1>
              <h4 className="text-center fw-light">
                Please enter your address below to get started. Our sign up
                process is quick and hassle free and will take you less than 5
                minutes to complete.
              </h4>
              <br />
              <AddressSearch
                apiKey="AIzaSyCjBT2NMBRcV0ksYz1SFv57NTvMgK_0cLY"
                onSubmit={handleAddressSearchResult}
              />
              <p className="mt-3 text-center">
                Enter the
                {' '}
                <strong>BUILDING</strong>
                {' '}
                number only - for example,
                {' '}
                <strong>
                  1 Freshwater Place,
                  Southbank
                </strong>
                .
                <br />
                (Your apartment, unit or level number is not required)
              </p>
            </Stack>
          </Col>
        </Row>
        <PineappleModal
          show={((submitted && !success) || (!closed && !computeSuccess(addr) && addr?.uniqueCode !== undefined && addr?.formatted_address !== undefined))}
          handleClose={handleClose}
          footer={(
            <Modal.Footer className="d-flex justify-content-center pt-0">
              <Button
                id="check_address_modal_fail"
                variant="primary"
                size="lg"
                onClick={() => setShowExpressInterest(true)}
              >
                <span>Register your address</span>
              </Button>
            </Modal.Footer>
        )}
        >
          <h1
            className="text-center text-primary fw-bold w-100"
          >
            We are sorry!
          </h1>
          <p className="lead text-center">
            Sorry, unfortunately Pineapple Net is not available at your address but we hope that will change soon.
          </p>

          <p className="lead text-center">
            Please register you details so we can keep you updated as we expand our network to get you.
          </p>
        </PineappleModal>
      </Container>
    </>
  );

  if (showExpressInterest) {
    return <ExpressInterest />;
  }

  return form;
};

export default CheckAddress;

import React from 'react';
import {
  Col,
  Row,
  Container,
  Spinner,
} from 'react-bootstrap';
import { useAppSelector } from '../app/hooks';
import { selectFinaleState } from '../features/finale/finaleSlice';
import { selectBookingState } from '../features/bookings/bookingsSlice';
import {
  pineAppleDay, pineAppleTime,
} from '../lib/utils';

const Fin = () => {
  const finaleState = useAppSelector(selectFinaleState);
  const bookingsState = useAppSelector(selectBookingState);
  if (finaleState.status === 'loading') {
    return (
      <div className="center">
        <Spinner
          className="center"
          animation="grow"
          variant="primary"
          style={{ width: '4rem', height: '4rem' }}
        />
      </div>
    );
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col>
          {finaleState.value.success ? (
            <h1 className="fw-bold text-primary text-center mb-4">
              Welcome onboard!
            </h1>
          ) : (
            <h1 className="fw-bold text-primary text-center mb-4">
              We&apos;re sorry!
            </h1>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center text-center">
        <Col>
          <div
            style={{ borderTop: '2px solid' }}
            className="position-sticky
          mt-2
          align-self-end
          top-0
          py-2
          border-primary"
          >
            {finaleState.value.success ? (
              <h4>We&apos;re getting ready to switch your internet over to us.</h4>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center text-center">
        <Col xs={8}>
          {finaleState.value.success ? (
            <>
              <p className="text-spaced">
                Your payment method has been validated in readiness for your connection. It&apos;s
                important to note that your 1st payment will occur 15 days after your service
                has been activated.
              </p>
              <p className="text-spaced">
                Your internet service is scheduled to be activated on
                {' '}
                { pineAppleDay(new Date(bookingsState.activeBookingId.start)) }
                .
              </p>
              <p className="text-spaced">
                Thanks again for choosing to connect your internet with
                Pineapple Net. We look forward to bringing you Refreshingly
                fast Internet.
              </p>
            </>
          ) : (
            <>
              <p className="text-spaced">{finaleState.value.err}</p>
              <hr />
              <p className="text-spaced">
                Unfortunately an error has occured with your order. We want to
                get you connected with Pineapple Net as quickly as possible.
                Please call us on 1300 857 501 or email us at
                sales@pineapple.net.au so that we can finalise your connection.
              </p>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Fin;

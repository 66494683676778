export const backendURL = process.env.REACT_APP_ENV === "production"
  ? "https://backend.support.prod.dgtek.link"
  : "https://backend.support.master.dgtek.link";

export const arrivyBookingID: { [key: string]: string } = {
  VIC: "ahRzfnRyYWNraW5nLWFwaS1tdWhpb3ItCxIMVXNlclNoYWRvdzIzGICA4MWSsMAJDAsSB0Jvb2tpbmcYgIDg1cCHsAsM",
  NSW: "ahRzfnRyYWNraW5nLWFwaS1tdWhpb3ItCxIMVXNlclNoYWRvdzIzGICA4MWSsMAJDAsSB0Jvb2tpbmcYgICQmb2KmAgM",
  QLD: "ahRzfnRyYWNraW5nLWFwaS1tdWhpb3ItCxIMVXNlclNoYWRvdzIzGICA4MWSsMAJDAsSB0Jvb2tpbmcYgICQkdeuiAsM",
  RAPIDNET:
    "ahRzfnRyYWNraW5nLWFwaS1tdWhpb3ItCxIMVXNlclNoYWRvdzIzGICA4MWSsMAJDAsSB0Jvb2tpbmcYgIDwn_ruzAkM",
};
